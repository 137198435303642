import { ReactNode, PropsWithChildren, useEffect } from "react";

type PopupProps = {
  className?: string;
  customDim?: ReactNode;
  innerClass?: string;
};

/**
 * 팝업 형태의 모달 컴포넌트
 * @param className
 * @param customDim
 * @param innerClass
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export default function Popup({
  className,
  customDim,
  innerClass,
  children,
}: PropsWithChildren<PopupProps>): JSX.Element {
  useEffect(() => {
    // 모달이 열릴 때 body 스크롤을 막음
    document.body.style.overflow = "hidden";

    // 컴포넌트가 언마운트될 때 원래대로 복원
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div
      className={
        `fixed inset-0 z-50 m-auto flex flex-col items-center justify-center ` +
        className
      }
    >
      {!!customDim ? (
        customDim
      ) : (
        <div className="fixed inset-0 z-40 h-full w-full bg-black opacity-80" />
      )}
      <div
        className={`z-50 flex h-auto max-h-[90vh] w-full items-center overflow-y-auto bg-white ${innerClass}`}
      >
        {children ? children : null}
      </div>
    </div>
  );
}
