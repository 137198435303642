export const setSessionStorage = (
  key: string,
  value: string | number | object,
): void => {
  if (typeof window !== "undefined" && sessionStorage) {
    const val =
      typeof value === "string" || typeof value === "number"
        ? value.toString()
        : JSON.stringify(value);
    sessionStorage.setItem(key, val);
  } else {
    console.log("sessionStorage is undefined");
  }
};

export const getSessionStorage = <T>(
  key: string,
  defaultValue?: T,
): T | string => {
  if (
    typeof window !== "undefined" &&
    sessionStorage &&
    !!sessionStorage.getItem(key)
  ) {
    let jsonData: T | string;
    try {
      jsonData = JSON.parse(sessionStorage.getItem(key) as string) as T;
    } catch (e) {
      jsonData = sessionStorage.getItem(key) as string;
    }
    return jsonData;
  } else {
    return defaultValue !== undefined ? defaultValue : "";
  }
};

export const removeSessionStorage = (key: string): void => {
  if (
    typeof window !== "undefined" &&
    sessionStorage &&
    !!sessionStorage.getItem(key)
  ) {
    sessionStorage.removeItem(key);
  }
};

export const setLocalStorage = (
  key: string,
  value: string | number | object,
): void => {
  if (typeof window !== "undefined" && localStorage) {
    const val =
      typeof value === "string" || typeof value === "number"
        ? value.toString()
        : JSON.stringify(value);
    localStorage.setItem(key, val);
  } else {
    console.log("localStorage is undefined");
  }
};

export const getLocalStorage = <T>(
  key: string,
  defaultValue?: T,
): T | string => {
  if (
    typeof window !== "undefined" &&
    localStorage &&
    !!localStorage.getItem(key)
  ) {
    let jsonData: T | string;
    try {
      jsonData = JSON.parse(localStorage.getItem(key) as string) as T;
    } catch (e) {
      jsonData = localStorage.getItem(key) as string;
    }
    return jsonData;
  } else {
    return defaultValue !== undefined ? defaultValue : "";
  }
};

export const removeLocalStorage = (key: string): void => {
  if (
    typeof window !== "undefined" &&
    localStorage &&
    !!localStorage.getItem(key)
  ) {
    localStorage.removeItem(key);
  }
};
