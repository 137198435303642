import React from "react";

export const PlanetColor = [
  ["#FFACA8", "#1AE7D8"],
  ["#FFA34F", "#868BFF"],
  ["#FFC34F", "#C58AFF"],
  ["#C5FFAA", "#FFC34F"],
  ["#1AE7D8", "#FF9CE3"],
  ["#4FB5FF", "#FFA34F"],
  ["#868BFF", "#C5FFAA"],
  ["#C58AFF", "#FFFFFF"],
  ["#FF9CE3", "#4FB5FF"],
  ["#FFFFFF", "#FFACA8"],
  ["#FFC0CB", "#4FB5FF"],
  ["#00FFFF", "#4FB5FF"],
];

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  hex = hex.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
}

interface CircleSvgProps {
  innerColor: string;
  outerColor: string;
  size?: number;
}

const CircleSvg: React.FC<CircleSvgProps> = ({
  innerColor,
  outerColor,
  size = 280,
}) => {
  const rgb = hexToRgb(innerColor) || { r: 0, g: 0, b: 0 };
  const colorMatrixValues = `0 0 0 0 ${rgb.r / 255} 0 0 0 0 ${rgb.g / 255} 0 0 0 0 ${rgb.b / 255} 0 0 0 1 0`;

  const glowSize = 20;
  const viewBoxSize = size + glowSize * 2;
  const circleRadius = size / 2;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id={`glow_${size}`}
          x="-50%"
          y="-50%"
          width="200%"
          height="200%"
        >
          <feGaussianBlur stdDeviation={glowSize / 3} result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter
          id={`shadow_${size}`}
          x="0"
          y="0"
          width="100%"
          height="100%"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-10" dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values={colorMatrixValues} />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_innerShadow"
          />
        </filter>
      </defs>

      {/* 배경 원 (검은색) */}
      <circle
        cx={viewBoxSize / 2}
        cy={viewBoxSize / 2}
        r={circleRadius}
        fill="#121212"
      />

      {/* glow 효과와 내부 색상을 가진 원 */}
      <g filter={`url(#glow_${size})`}>
        <circle
          cx={viewBoxSize / 2}
          cy={viewBoxSize / 2}
          r={circleRadius}
          fill={innerColor}
          filter={`url(#shadow_${size})`}
        />
      </g>
    </svg>
  );
};

export default CircleSvg;
